<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                Major Project: Research Agenda Statement
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Portfolio Details Area  -->
    <div class="rn-portfolio-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col>
            <div class="portfolio-details">
              <div class="row row--35 mb--50">
                <div class="col-lg-12">
                  <div class="inner">
                    <div class="section-title">
                      <h2 class="heading-title">Research Statement</h2>
                      <p></p>
                      <h4>Introduction</h4>
                      <p class="description">
                        As an educator, my goal is student success, and to me,
                        that means not just in the classroom but outside of the
                        classroom. I want the technology I am teaching to be
                        understood well enough or up-to-date, so students are as
                        prepared in their four years of getting a degree to get
                        a job. As I grow as an educator and get more involved in
                        research, I hope to succeed by my research priority
                        revolving around technology and making sure students
                        have the relevant skills needed to work in the workforce
                        after their four years of getting a degree.
                      </p>
                      <h4>Current Projects</h4>
                      <p class="description">
                        Currently, I am working on helping create the curriculum
                        for our Web and User Experience program at Weber State
                        University. We started on this a few years back, but we
                        continue to adjust. Our current adjustments are creating
                        two pathways, one for full-stack web development and the
                        other focused on user experience. Each path has pieces
                        of the other so students can become familiar with the
                        other areas. With this project, we have met with our
                        industry advisory committee to get feedback to cover
                        items in need. As we start getting students through our
                        project, this is where my future research idea comes in.
                        With technology changing rapidly, are students prepared
                        for the workforce after a four-year degree in web and
                        user experience? Even with insight from our industry
                        advisory committee, does the technology we teach during
                        student's first year still relevant in their fourth year
                        or upon graduating and trying to go into the workforce?
                        Technologies in the web and user experience area change
                        so quickly that we want to make sure students are as
                        prepared as possible and adapt. As the program grows, I
                        want to gather data from our students to adapt as
                        quickly to these changes as possible.
                      </p>
                      <h4>Future Work</h4>
                      <p class="description">
                        I am interested in exploring the student's perception of
                        whether they are prepared for the workforce after
                        completing a four-year degree in web and user
                        experience. With how fast technology changes in this
                        field, I find it essential to know whether the
                        curriculum being taught is up-to-date and is valuable to
                        students and if they feel prepared for the workforce.
                        Multiple sources purport students who complete a
                        four-year college degree will not have the skills to be
                        relevant in the current workforce (synergisticIT, 2021).
                        With student success being a high priority in higher
                        education, it is essential to understand the
                        implications of providing a degree that is perceived as
                        useful for procuring gainful employment upon graduation
                        (Giannakos et al., 2017).
                      </p>
                      <p class="description">
                        As an instructor in the School of Computing at Weber
                        State Univerity (WSU), it is an important goal to
                        attract students to the program and retain those who are
                        currently attending courses. The purpose of the study is
                        to evaluate students' perceptions to influence future
                        program designs. The research needs to pinpoint what
                        skills or technologies are relevant or not by the time a
                        student graduates and use that to make adjustments to
                        the program design, so students are prepared for the
                        workforce. With technology changing rapidly, are
                        students prepared for the workforce after a four-year
                        degree in web and user experience. Questions for this
                        research would include how viable is a student in the
                        workforce after a four-year web and user experience
                        degree? Do students report a preference related to
                        studying current technologies in their academic program?
                      </p>
                      <h4>References</h4>
                      <p class="description">
                        Giannakos, M. N., Pappas, I. O., Jaccheri, L., &
                        Sampson, D. G. (2017). Understanding student retention
                        in computer science education: The role of environment,
                        gains, barriers, and usefulness. Education and
                        Information Technologies, 22(5), 2365-2382.
                      </p>
                      <p class="description">
                        synergisticIT. (2021, May 9). Synergistic It.
                        https://www.synergisticit.com/tech-companies-not-hire-computer-science-graduate
                      </p>

                      <div class="portfolio-details-btn mt--30">
                        <router-link
                          class="btn-default btn-border"
                          to="/LDTE5870"
                          >Back to LDTE 5870</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
import feather from "feather-icons";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      items: [
        {
          thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
          src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
        },
      ],
      listOne: [
        {
          id: 1,
          icon: "check",
          desc: `5 PPC Campaigns the majority.`,
        },
        {
          id: 2,
          icon: "check",
          desc: `Digital Marketing going to.`,
        },
        {
          id: 3,
          icon: "check",
          desc: ` Marketing Agency passage of.`,
        },
        {
          id: 4,
          icon: "check",
          desc: ` Seo Friendly you are going.`,
        },
      ],
      socialList: [
        {
          url: "https://www.facebook.com/",
          icon: "facebook",
        },
        {
          url: "https://www.linkedin.com/",
          icon: "linkedin",
        },
        {
          url: "https://instagram.com/",
          icon: "instagram",
        },
        {
          url: "https://twitter.com/",
          icon: "twitter",
        },
      ],
      moreProjects: [
        {
          src: require("../../assets/images/portfolio/related-image-01.jpg"),
          title: "Digital Analysis",
          categorie: "Technique",
        },
        {
          src: require("../../assets/images/portfolio/related-image-02.jpg"),
          title: "Plan Management",
          categorie: "Planning",
        },
      ],
      index: null,
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
